/* ------------------user-profile.css------------------- */
/*Transaction Page*/
/*.transaction_sec{
  zoom: 0.8;
}*/
.user_text {
  color: var(--primary);
  font-family: 'BwModelicaBold';
  font-size: 12px;
}
.new_head {
  font-family: 'BwModelicaBold';
}
.new_form_control {
  background-color: var(--dark-9);
  border: 1px solid var(--light-1);
  color: var(--light-1);
  border-radius: 6px;
  padding: 0 13px;
  height: 45px;
  font-family: 'BwModelicaMedium';
  width: -webkit-fill-available;
}
.email-span {
  grid-column: span 2;
}
.transaction_send_btn {
  padding: 20px 34px;
  margin-left: 35vmax;
}
.middle-align-prop {
  margin: 30px 0px;
}
.reg {
  font-family: 'BwModelicaRegular';
}
.med {
  font-family: 'BwModelicaMedium';
}
.bol {
  font-family: 'BwModelicaBold';
}
.movie_info_title_trans {
  font-size: 22px;
  font-weight: 400;
  font-family: 'BwModelicaMedium';
}
.trans_ticket {
  display: block;
}
.fnb_trans {
  display: flex;
}
.profile_tic_heading {
  color: var(--light-1);
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-family: 'BwModelicaMedium';
}
.para_profile {
  font-weight: 700;
  font-family: 'BwModelicaMedium';
  font-size: 16px;
  padding-top: 5px;
  word-break: break-word;
  color: white;
}
.prop_p {
  font-size: 12px;
  font-weight: 700;
  font-family: 'BwModelicaMedium';
  text-align: left;
  word-break: break-word;
  width: 100%;
}
.profile_good {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fnb_profile {
  display: flex;
}
.top_prop {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: #29282e;
  box-shadow: 0px 15px 20px rgb(0 0 0 / 42%);
  padding: 20px 34px;
}
.ticket_bottom_profile {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 20px 34px;
}
.ticket-header-profile {
  background-color: var(--primary);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0px 0px;
}
.btn_alignment {
  display: flex;
  grid-template-columns: 40% 25% 12% 13%;
  /*grid-gap: 10px;*/
  flex-wrap: wrap;
}
.btn_alignment > button:nth-child(2) {
  margin: 0px 5px;
}
.prof_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 19px;
  font-size: 12px;
  line-height: 19px;
  border-radius: 4px;
  /*padding: 0 8px;*/
  color: var(--light-5);
  background-color: var(--dark-3);
  border: 1px solid var(--dark-3);
  font-family: 'BwModelicaRegular';
  pointer-events: none;
}
/*Transaction Page*/
.fs-12 {
  font-size: 12px !important;
}

.modelica-bold {
  font-family: 'Modelica bold';
}

.user-profile-page {
  font-family: 'Modelica Light';
  font-weight: 600;
}

.user-profile-page .close-account span {
  cursor: pointer;
}

.user-profile-page .nav-pills::-webkit-scrollbar {
  width: 05px;
  height: 05px;
}

.user-profile-page .profile-box {
  background: var(--dark-2);
  border-radius: 12px;
}

.user-profile-page .profile-header {
  padding: 30px 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(141, 141, 143, 0.5);
}

.user-profile-page .profile-header .custom-upload {
  position: absolute;
  top: 0;
}

.user-profile-page .profile-header .custom-upload label {
  width: 100%;
  height: auto;
}

.user-profile-page .user-img {
  position: relative;
  margin-right: 18px;
}

.user-profile-page .user-name h6 {
  /* font-size: 24px; */
  font-weight: bold;
  font-family: 'BwModelicaBold';

  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.user-profile-page .user-name p {
  font-size: 16px;
}

.user-profile-page .profile-content .nav-pills {
  padding: 20px 1rem 0 1rem;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.user-profile-page .profile-content .nav-pills .nav-item {
  flex: 0 0 58%;
  font-size: 15px;
  font-weight: bold;
  font-family: 'Modelica Bold';
}

.user-profile-page .nav .nav-item .nav-link {
  background-color: transparent;
  color: var(--light-5);
  padding: 0 0 15px 0;
  white-space: nowrap;
}

.user-profile-page .nav .nav-item .nav-link > div {
  display: flex;
  align-items: center;
}

.user-profile-page .nav .nav-item .icon-img {
  height: 24px;
  width: 25px;
  margin-right: 18px;
}

.user-profile-page .profile-content .nav-pills .nav-item .active {
  color: var(--primary);
}

.user-profile-page .profile-content .nav-pills .nav-item .active svg path {
  fill: var(--primary);
}

/*************************User Settings*****************************/

.user-profile-page .user-settings .custom-form .form-control {
  margin-bottom: 24px;
}
.custom-form label {
  font-family: 'BwModelicaBold';
}
.user-profile-page .user-settings .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 35px 25px 35px;
  border-bottom: 1px solid var(--light-5);
}

.user-profile-page .user-settings .redo-icon {
  cursor: pointer;
}

.user-profile-page .user-settings .content {
  padding: 25px 35px 25px 35px;
}

.user-profile-page .user-settings .content > div {
  padding-bottom: 4px;
}

.user-profile-page .user-settings .content > div p:first-child {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.5px;
  font-family: 'Modelica thin';
}

.user-profile-page .user-settings .content > div p:last-child {
  font-size: 16px;
  padding-top: 12px;
  font-family: 'Modelica thin';
}

.user-profile-page .user-settings .content .custom-form {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
}

.user-profile-page .user-settings .content .custom-form .form-control {
  font-size: 14px;
  color: var(--light-5);
  font-weight: 600;
  border-color: var(--light-5);
  font-family: 'BwModelicaMedium';
}

.user-profile-page .user-settings .content .custom-form .form-control:focus {
  box-shadow: none;
}

.user-profile-page .user-settings .content .custom-form .form-control:disabled,
.user-profile-page .user-settings .content .custom-form .form-control.disabled {
  color: var(--light-1);
  border-color: var(--light-1);
}

.user-profile-page .user-settings .content .btn-update {
  background-color: var(--primary);
  border-radius: 08px;
  width: 120px;
  font-size: 16px;
  font-weight: 600;
  color: var(--light-5);
  height: 56px;
  border: 1px solid var(--primary);
  transition: background-color 0.5s, color 0.25s, transform 0.5s;
}

.user-profile-page .user-settings .content .btn-update:hover {
  background-color: var(--light-5);
  color: var(--primary);
}

.user-profile-page .user-settings .content .btn-update:active {
  transform: scale(0.9);
}

.user-profile-page .user-settings .content .btn-cancel {
  background: transparent;
  border-radius: 08px;
  width: 120px;
  font-size: 16px;
  color: var(--light-5);
  height: 56px;
}

/**********Notifications***********/

.user-profile-page .notifications {
  padding-bottom: 30px;
  height: 90vh;
  overflow-y: scroll;
}

.user-profile-page .notifications .box {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  align-items: center;
  border-bottom: 1px solid var(--light-1);
  position: relative;
}

.user-profile-page .notifications .box .notify-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile-page .notifications .box .notify-content .notify-desc {
  padding-left: 15px;
}

.user-profile-page .notifications .box .notify-delete {
  position: absolute;
  top: 0;
  right: 10px;
}

.user-profile-page .notifications .box .notify-delete img {
  height: 10px;
  width: 10px;
  cursor: pointer;
}

.user-profile-page .notifications .box .notify-content .notify-desc .text {
  font-family: 'Modelica Light';
  line-height: 22px;
  font-size: 13px;
}

.user-profile-page .notifications .box .notify-content .notify-desc .time-text {
  color: var(--light-1);
  font-size: 14px;
  padding-top: 05px;
}

@media (min-width: 1281px) {
  .user-profile-page .user-name h6 {
    font-size: 24px;
  }

  .user-profile-page .profile-box {
    /* background: var(--dark-2); */
    border-radius: 12px;
  }

  .user-profile-page .profile-content .nav-pills {
    /* padding: 50px 0 0 40px; */
  }
}

@media (min-width: 768px) and (max-width: 1021px) and (orientation: portrait) {
  .user-profile-page .profile-header {
    padding: 20px;
  }

  .user-profile-page .user-name h6 {
    font-size: 20px;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .user-profile-page .profile-header {
    padding: 16px;
  }
}

/********My Transactions**********/

.user-profile-page .transactions .custom-border {
  border-top: 1px solid var(--light-1);
}

.user-profile-page .transactions .booked-tickets {
  height: 90vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  /* scroll-snap-type: y mandatory; */
}

.booked-tickets::-webkit-scrollbar-track,
.notifications::-webkit-scrollbar-track,
.user-profile-page .profile-content .nav-pills::-webkit-scrollbar-track {
  background-color: var(--light-5) !important;
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 5px;
}

/* Handle */

.booked-tickets::-webkit-scrollbar-thumb,
.notifications::-webkit-scrollbar-thumb,
.user-profile-page .profile-content .nav-pills::-webkit-scrollbar-thumb {
  background-color: var(--primary) !important;
  border-radius: 3px;
}

/* Handle on hover */

.booked-tickets::-webkit-scrollbar-thumb:hover,
.notifications::-webkit-scrollbar-thumb:hover,
.user-profile-page .profile-content .nav-pills::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary);
}

.user-profile-page .transactions .ticket {
  /* padding: 10px 20px 30px 20px; */
  /*border: 1px solid #edf2f5;*/
  border-radius: 8px 8px 8px 8px;
  background-color: #17161d;
  /* margin: 40px 20px; */
}

.user-profile-page .transactions .book-ticket {
  margin: 40px 20px;
  /* border-bottom: 1px solid var(--light-1); */
  position: relative;
  overflow: hidden;
  /* scroll-snap-align: center; */
}

/* .user-profile-page .transactions .book-ticket:not(:last-of-type) {
  border-top: 1px solid var(--light-4);
} */

.user-profile-page .transactions .trans-barcode svg {
  width: 100%;
  height: 100%;
}

.user-profile-page .transactions .grid-25 {
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  align-items: stretch;
  font-weight: 600;
  font-size: clamp(12px, 1vw, 14px);
  padding-bottom: 8px;
  grid-template-columns: repeat(4, 23%);
  grid-gap: 2%;
  max-width: 500px;
}

.user-profile-page .transactions .grid-25 > div:nth-child(2),
.user-profile-page .transactions .grid-25 > p:nth-child(2),
.user-profile-page .transactions .grid-25 > div:nth-child(3),
.user-profile-page .transactions .grid-25 > p:nth-child(3) {
  text-align: center;
}

.user-profile-page .transactions .grid-25 > div:nth-child(4),
.user-profile-page .transactions .grid-25 > p:nth-child(4) {
  text-align: right;
}

.booked-tickets .concessions {
  padding: 8px 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.booked-tickets .concessions > p {
  font-size: 16px;
  font-family: 'Modelica Bold';
  font-weight: 700;
  color: var(--light-1);
  margin-bottom: 8px;
}

/*.user-profile-page .transactions .ticket::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid var(--light-5);
  top: -12px;
  left: -10px;
  background: #17161D;
}*/

.user-profile-page .transactions .ticket::after {
  /*content: "";
  position: absolute;
  height: 20px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid var(--light-5);
  top: -12px;
  right: -10px;
  background: var(--dark-2);*/
}

.user-profile-page .transactions .ticket .footer::before {
  content: '';
  position: absolute;
  height: 20px;
  width: 25px;
  background: var(--dark-2);
  border-radius: 50%;
  border: 1px solid var(--light-5);
  bottom: -12px;
  left: -10px;
}

.user-profile-page .transactions .ticket .footer::after {
  content: '';
  position: absolute;
  height: 20px;
  width: 25px;
  background: var(--dark-2);
  border-radius: 50%;
  border: 1px solid var(--light-5);
  bottom: -12px;
  right: -10px;
}

.user-profile-page .transactions .right .date-day,
.user-profile-page .transactions .right .date-time {
  font-size: 10px;
}

.user-profile-page .transactions .right .date-day h3,
.user-profile-page .transactions .right .date-time h3 {
  font-size: 21px;
}

.user-profile-page .transactions .footer {
  /* display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
  grid-row-gap: 10px; */
  display: flex;
  flex-flow: row wrap;
  grid-gap: 16px 20px;
  padding: 10px 10px 05px 10px;
  align-items: flex-start;
}

.user-profile-page .transactions .footer > * {
  flex: 1 0 100%;
  text-align: center;
}

.user-profile-page .transactions .footer .footer-actions {
  flex-grow: 1;
  align-self: center;
}

.user-profile-page .transactions .footer p:first-child {
  color: var(--light-1);
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 600;
}

.user-profile-page .transactions .footer p:last-child {
  font-weight: 700;
  font-weight: bold;
  font-family: 'Modelica Bold';
  font-size: 14px;
  letter-spacing: 0.9px;
  padding-top: 08px;
  word-break: break-all;
}

.user-profile-page .transactions .header .user-custom-dropdown {
  width: 50%;
}

.user-profile-page .transactions .ticket .left {
  display: flex;
  align-items: center;
}

.user-profile-page .transactions .movie-img img {
  /*border-radius: 08px;*/
  width: 90%;
}

.user-profile-page .transactions .movie-img {
  flex: 0 0 45%;
}

.user-profile-page .transactions .movie-content {
  margin-left: -15px;
  flex: 0 0 65%;
}

.user-profile-page .transactions .movie-content .heading {
  font-size: 18px;
  font-weight: bold;
  font-family: 'Modelica Bold';

  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.user-profile-page .transactions .movie-content .features {
  font-size: 12px;
  padding: 08px 0;
}

.user-profile-page .transactions .movie-content .features p {
  padding-bottom: 4px;
}

.user-profile-page .transactions .right > div {
  position: relative;
  justify-content: center;
  display: grid;
}

.user-profile-page .transactions .right {
  /*padding-top: 10px;*/
  height: 45vh;
}

.user-profile-page .transactions .date-time {
  position: relative;
}

.user-profile-page .transactions .date-time div {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
}

.user-profile-page .transactions .movie-content .booked-details {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-column-gap: 20px;
}

.user-profile-page .transactions .movie-content .booked-details div {
  font-size: 12px;
}

.user-profile-page .transactions .movie-content .booked-details div p:last-child {
  padding-top: 05px;
}

/***************Customer Card*****************/

.user-profile-page .customer-card .header {
  padding: 20px 40px;
  border-bottom: 1px solid var(--light-1);
}

.user-profile-page .customer-card .middle {
  padding: 25px 10px;
  border-bottom: 1px solid var(--light-1);
}

.user-profile-page .customer-card .middle > div {
  margin-bottom: 10px;
}

.user-profile-page .customer-card .box {
  display: grid;
  grid-template-columns: 85% 14%;
  grid-column-gap: 1%;
  padding: 15px 0;
}

.user-profile-page .customer-card .content .top {
  /* padding: 30px; */
  padding: 20px 0 0 10px;
  border-bottom: 1px solid var(--light-1);
  text-align: center;
}

.user-profile-page .customer-card .content h1 {
  font-weight: bold;
  font-family: 'Modelica Bold';

  color: var(--green-shade);
  font-size: 60px;
  font-family: 'Modelica bold';
}

.user-profile-page .customer-card .content .box .icon {
  position: relative;
}

.user-profile-page .customer-card .points {
  font-family: 'Modelica bold';
}

.user-profile-page .customer-card .content .bottom {
  padding: 25px 12px;
}

.user-profile-page .customer-card .content .box .plus-icon h1 {
  font-family: 'Modelica thin';
  margin: 0;
  position: absolute;
  top: -7px;
  right: 9px;
  font-size: 45px;
  font-weight: bold;
  font-family: 'Modelica Bold';

  color: var(--green-shade);
}

.user-profile-page .customer-card .content .box .minus-icon h1 {
  font-weight: bold;
  font-family: 'Modelica Bold';

  color: var(--primary);
  font-family: 'Modelica thin';
  margin: 0;
  position: absolute;
  top: -9px;
  right: 11px;
  font-size: 45px;
}

.user-profile-page .customer-card .content .box .desc .text {
  font-size: 14px;
  font-family: 'Modelica thin';
  font-weight: 600;
  line-height: 18px;
}

.user-custom-dropdown {
  border: 1px solid var(--light-5);
  height: 50px !important;
  border-radius: 08px;
  color: var(--light-5);
  font-size: 16px !important;
  background: var(--dark-5);
}

.user-custom-dropdown .dropdownClassic {
  color: var(--light-1) !important;
  padding-left: 20px !important;
}

.user-custom-dropdown .btn {
  position: relative;
  padding: 0 24px;
}

.user-custom-dropdown .dropdown-toggle:after {
  border: none;
  margin: 0;
  background: url('../pictures/svgs/dropdown-arrow.svg') no-repeat 50% 50%;
  height: 9px;
  width: 14px;
  right: 26px;
  position: absolute;
  top: 45%;
}

.user-custom-dropdown .dropdownItems {
  background-color: var(--dark-1) !important;
  border: 1px solid var(--light-1);
  border-radius: 0px 0px 8px 8px;
  padding: 0;
}

.user-custom-dropdown .dropdownItems.show {
  border-top: none;
}

.user-custom-dropdown .dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--light-1);
}

.user-custom-dropdown .dropdownItems .dropdown-item {
  height: 54px;
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.user-history-filters {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 8px 10px;
  margin: 10px 5px;
  cursor: pointer;
}

.user-history-filters-selected {
  border-color: transparent;
  background-color: var(--primary);
}

.user-history-table-container {
  border: 1px solid #515151;
  border-radius: 10px;
  width: 98%;
  margin: 5px auto;
  max-height: 500px;
  /* overflow: scroll; */
  overflow: auto;
}
.user-history-table {
  /* max-height: 500px; */
  width: 98%;
  /* overflow: scroll; */
  margin: auto;
}

.user-history-table-heading {
  color: var(--primary);
}

.user-history-table-row,
.user-history-table-heading {
  border-bottom: 1px solid #515151;
}
.user-history-table-row > td,
.user-history-table-heading > th {
  padding: 10px;
}

.user-history-ticket-btn {
  background: transparent;
  border: none;
}
/******************RESPONSIVE ***********************/

/******Mobile Landscape*****/

@media (max-width: 767px) {
  .booked-tickets .concessions {
    padding: 8px 10px;
  }

  .user-profile-page .user-settings .content,
  .user-profile-page .user-settings .header {
    padding: 16px 10px;
  }

  .user-profile-page .user-settings .content .custom-form {
    grid-template-columns: repeat(1, 1fr);
    /*grid-gap: 20px;*/
  }
  .new_form_control {
    width: 90%;
  }
}

@media (min-width: 480px) {
  .user-profile-page .profile-content .nav-pills .nav-item {
    flex: 0 0 50%;
  }
}

/*******Tablet Portrait******/

@media (min-width: 768px) {
  .user-profile-page .profile-content .nav-pills .nav-item {
    flex: 0 0 27% !important;
  }
}

@media (min-width: 767px) {
  .user-profile-page .transactions .footer .footer-actions {
    margin-top: 8px;
  }
}

/*******Tablet Landscape******/

@media (min-width: 992px) {
  .user-profile-page .profile-content .nav-pills {
    padding: 30px 0px 30px 20px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .user-profile-page .profile-content .row > div:last-child {
    border-left: 1px solid rgba(141, 141, 143, 0.5);
  }

  .user-profile-page .customer-card .middle {
    display: grid;
    padding: 30px 40px 20px 40px;
    grid-template-columns: 45% 45%;
    grid-column-gap: 10%;
  }

  .user-profile-page .customer-card .box {
    /* padding: 30px 40px; */
    grid-template-columns: 70% 20%;
    grid-column-gap: 10%;
  }
}

/***Laptops and Larger Devices****/

@media (min-width: 1200px) {
  .user-profile-page .profile-content .nav-pills {
    padding: 50px 0 0 40px !important;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .user-profile-page .profile-content .nav-pills .nav-item {
    font-size: 16px;
  }

  .user-profile-page .profile-content .row > div:last-child {
    border-left: 1px solid rgba(141, 141, 143, 0.5);
  }

  .user-profile-page .notifications {
    padding-bottom: 30px;
  }

  .user-profile-page .notifications .box {
    padding: 30px 40px;
  }

  .user-profile-page .notifications .box .notify-content .notify-desc {
    padding: 0 80px 0 15px;
  }

  .user-profile-page .notifications .box .notify-delete {
    position: relative;
  }

  .user-profile-page .notifications .box .notify-delete img {
    height: 15px;
    width: 15px;
  }

  .user-profile-page .nav .nav-item .nav-link {
    padding: 0.5rem 1rem 30px 1rem;
  }

  .user-profile-page .notifications .box .notify-content .notify-desc .text {
    font-size: 16px;
  }

  .user-profile-page .customer-card .box {
    /* padding: 30px 40px 0 30px; */
    grid-template-columns: 70% 5%;
    grid-column-gap: 20%;
    padding: 20px 0 0 0;
  }

  .user-profile-page .customer-card .content .bottom {
    padding: 30px 40px;
  }

  .user-profile-page .customer-card .content .top {
    padding: 30px 0 10px 0;
  }

  .user-profile-page .customer-card .content .box .desc .text {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (min-width: 720px) {
  .user-profile-page .transactions .header {
    padding: 20px;
  }

  .user-profile-page .transactions .ticket .header {
    display: grid;
    grid-template-columns: 60% 30%;
    grid-column-gap: 10%;
    align-items: center;
    background-color: #17161d;
  }

  .user-profile-page .transactions .movie-content .heading {
    font-size: 20px;
  }

  .user-profile-page .transactions .movie-content .features {
    font-size: 14px;
    padding: 14px 0;
  }

  .user-profile-page .transactions .movie-content .features p {
    padding-bottom: 12px;
  }

  .user-profile-page .transactions .movie-content .booked-details .fsd-18 {
    font-size: 18px;
  }

  .user-profile-page .transactions .movie-content .booked-details .fsd-16 {
    font-size: 16px;
  }

  .user-profile-page .transactions .movie-content .booked-details .fsd-14 {
    font-size: 14px;
  }

  .user-profile-page .transactions .right div:first-child {
    /*display: flex;
    align-items: center;*/
  }

  .user-profile-page .transactions .right .date-day,
  .user-profile-page .transactions .right .date-time {
    font-size: 14px;
  }

  .user-profile-page .transactions .right .date-day h3,
  .user-profile-page .transactions .right .date-time h3 {
    font-size: 1.75rem;
  }

  .user-profile-page .transactions .footer {
    /* display: grid; */
    /* grid-template-columns: 30% 15% 15% 15% 17%; */
    /* grid-template-columns: 23% 15% 15% 15% 23%; */
    /* grid-template-columns: 22% 14% 14% 15% 27%;
    grid-column-gap: 20px; */
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    grid-gap: 16px 20px;
    padding: 0 20px 20px 20px;
  }

  .user-profile-page .transactions .footer > * {
    flex: 0 0 20%;
    text-align: left;
  }
}

.profile-accordion .custom-accordian {
  background: var(--dark-2);
  margin: 15px 0px;
}

.profile-accordion .custom-accordian .btn {
  background: transparent;
  border: 1px solid transparent;
}

@media screen and (min-width: 100px) and (max-width: 600px) {
  .transaction_send_btn {
    padding: 20px;
  }
  .btn_alignment > button:nth-child(2) {
    margin: 0px;
  }
  .btn_alignment > button:nth-child(3) {
    margin-left: 5px;
  }
  .user-profile-page .profile-content .nav-pills {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 75px;
  }
  .ticket-header-profile-transaction {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 9px;
  }
  .transaction_send_btn {
    margin-left: 0px;
  }
  .movie_info_title_trans {
    font-size: 14px;
  }
  .btn_alignment {
    grid-template-columns: 20% 25% 30%;
    white-space: nowrap;
  }
  .prof_btn {
    font-size: 10px;
  }

  .user-profile-page .transactions .ticket .left {
    padding: 10px;
  }
  .user-profile-page .transactions .movie-img {
    flex: 0 0 50%;
  }
  .user-profile-page .transactions .right {
    height: 35vh;
  }
  .top_prop {
    grid-template-columns: 50% 50%;
    gap: 25px;
    padding: 20px;
  }
  .para_profile {
    font-size: 12px;
  }
  .mob_prof_btn {
    grid-column: span 2;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .ticket_bottom_profile {
    grid-template-columns: 50% 50%;
    padding: 20px;
  }
  .mob_span_profile {
    grid-column: span 2;
  }
  .prop_p {
    font-size: 12px;
    white-space: pre-wrap;
    width: unset;
  }
  .mob_prop_p {
    width: 50%;
  }
  .fnb_profile {
    justify-content: space-between;
  }
  .mob_pad {
    padding-top: 10px;
  }
  .mob_text {
    font-size: 12px;
  }
  .middle-align-prop {
    margin: 10px 0px;
  }
}
@media screen and (min-width: 700px) and (max-width: 900px) {
  .user-profile-page .transactions .right {
    height: unset;
  }
  .btn_alignment {
    grid-template-columns: 50% 35% 12% 20%;
  }
}
