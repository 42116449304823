.body-inner-story {
  /* background-color: #212027; */
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.section-pad-top {
  padding-top: 30px;
}
.section-pad-bottom {
  padding-bottom: 30px;
}

@media (min-width: 1024px) {
  .section-pad-top {
    padding-top: 50px;
  }
  .section-pad-bottom {
    padding-bottom: 50px;
  }
}

.animate-left {
  -webkit-animation-name: left;
  animation-name: left;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
@-webkit-keyframes left {
  0% {
    -webkit-transform: translate(40%);
    transform: translate(40%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes left {
  0% {
    -webkit-transform: translate(40%);
    transform: translate(40%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .banner-section {
    margin-bottom: 20px;
  }
}

ngb-alert {
  display: block;
}
.header-banner[_ngcontent-c13] h2[_ngcontent-c13] {
  font-size: 26px;
  margin-bottom: 0 !important;
}
.header-banner[_ngcontent-c13] h4[_ngcontent-c13] {
  font-size: 20px;
}
.header-banner[_ngcontent-c13] {
  color: #007e9e;
}
.brand-title-text[_ngcontent-c13] .btn-link[_ngcontent-c13] {
  padding: 0;
  font-size: 18px;
  display: block;
  text-align: left;
  margin-top: 25px;
  color: #00add6;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .header-banner[_ngcontent-c13] h2[_ngcontent-c13] {
    font-size: 30px;
    margin-bottom: 10px !important;
  }
  .header-banner[_ngcontent-c13] h4[_ngcontent-c13] {
    font-size: 26px;
  }
}

.brand-image[_ngcontent-c14] img[_ngcontent-c14] {
  width: 100%;
}
.brand-title-content[_ngcontent-c14] {
  justify-content: flex-start;
  padding: 20px;
}
.brand-title-content[_ngcontent-c14] h1[_ngcontent-c14] {
  font-size: 26px;
}
.brand-title-content[_ngcontent-c14] p[_ngcontent-c14] {
  font-size: 20px;
  line-height: 30px;
}
.brand-title-content[_ngcontent-c14] a[_ngcontent-c14] {
  font-size: 18px;
  color: #2d2b2a;
  padding: 0;
}
.brand-title-content[_ngcontent-c14] a[_ngcontent-c14] img[_ngcontent-c14] {
  width: 26px;
  margin-left: 10px;
}
@media (min-width: 1200px) {
  .brand-title-content[_ngcontent-c14] {
    padding: 20px 202px 20px 95px;
  }
}
@media (min-width: 768px) {
  .header-banner[_ngcontent-c14] h2[_ngcontent-c14] {
    font-size: 30px;
    margin-bottom: 25px !important;
  }

  .brand-title-content[_ngcontent-c14] h1[_ngcontent-c14] {
    font-size: 40px;
    line-height: 45px !important;
  }
}
@media (max-width: 767px) {
  .brand-title-content[_ngcontent-c14] p[_ngcontent-c14] {
    font-size: 16px;
  }
}
.body-inner[_ngcontent-c15] {
  margin: 0;
}
.header-banner[_ngcontent-c15] h2[_ngcontent-c15] {
  font-size: 26px;
  margin-bottom: 0 !important;
}
.header-banner[_ngcontent-c15] h4[_ngcontent-c15] {
  font-size: clamp(14px, 1.4vw, 20px);
  font-weight: 600;
}
.header-banner[_ngcontent-c15] {
  color: var(--primary);
}
.cntl[_ngcontent-c15] {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.cntl-center[_ngcontent-c15] {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.cntl-bar[_ngcontent-c15] {
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
}
.cntl-state[_ngcontent-c15] {
  position: relative;
  width: 100%;
  min-height: 200px;
}
.cntl-state[_ngcontent-c15]::after {
  display: block;
  content: ' ';
  clear: both;
}
.cntl-icon[_ngcontent-c15] {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-color: var(--primary);
  border: 2px solid #858585;
  position: absolute;
  top: 70px;
  text-align: center;
  line-height: 80px;
  font-size: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-inner-brand[_ngcontent-c15] .cntl-icon[_ngcontent-c15] {
  display: none;
}
.cntl-icon[_ngcontent-c15] h1[_ngcontent-c15],
.cntl-icon[_ngcontent-c15] h4[_ngcontent-c15] {
  color: #fff;
  margin: 0 !important;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.1 !important;
}
.cntl-icon[_ngcontent-c15] h4[_ngcontent-c15] {
  margin: 0 !important;
  font-size: 24px;
  font-weight: 400;
}
.cntl-icon[_ngcontent-c15] h4[_ngcontent-c15] small[_ngcontent-c15] {
  display: block;
}
.cntl-content[_ngcontent-c15] h1[_ngcontent-c15] {
  font-size: 40px !important;
  color: var(--primary);
}
.cntl-content[_ngcontent-c15] p[_ngcontent-c15] {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  color: #000;
}
.cntl-content[_ngcontent-c15] .btn-link[_ngcontent-c15] {
  padding: 0;
  text-transform: uppercase;
  color: #00add6;
}
.cntl-content[_ngcontent-c15] .btn-link[_ngcontent-c15]:hover {
  color: #141414;
}
.cntl-content[_ngcontent-c15] .btn-link[_ngcontent-c15] .fa[_ngcontent-c15] {
  margin-left: 8px;
}
.cntl-content[_ngcontent-c15] {
  width: 58.33333333%;
  background-color: rgba(238, 238, 238, 0.25);
  border-radius: 8px;
  float: right;
  opacity: 0;
  position: relative;
  margin-left: -40%;
  padding: 0 130px 0 220px;
}
.body-inner-brand[_ngcontent-c15] .cntl-content[_ngcontent-c15] {
  width: 50%;
  padding: 0;
  float: none;
}
.cntl-state[_ngcontent-c15]:nth-child(2n + 2) .cntl-content[_ngcontent-c15] {
  float: left;
  margin-right: -40%;
}
.cntl-image[_ngcontent-c15] {
  opacity: 0;
  width: 41.66666667%;
  float: right;
}
.cntl-state[_ngcontent-c15]:nth-child(2n + 1) .cntl-image[_ngcontent-c15] {
  float: left;
}
.cntl-image[_ngcontent-c15] img[_ngcontent-c15] {
  width: 100%;
}
.small-img[_ngcontent-c15] {
  width: auto;
  max-width: 100%;
  height: auto;
}
.cntl-image.small-img[_ngcontent-c15] {
  float: left;
}
@media (max-width: 991px) {
  .cntl-image[_ngcontent-c15] img[_ngcontent-c15] {
    display: block;
    margin: 0 auto;
  }
}
.cntl-content[_ngcontent-c15] h4[_ngcontent-c15] {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.cntl-animate[_ngcontent-c15]:nth-child(2n + 1) .cntl-image[_ngcontent-c15] {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.cntl-animate[_ngcontent-c15]:nth-child(2n + 2) .cntl-image[_ngcontent-c15] {
  -webkit-animation-name: slide-right;
  -webkit-animation-duration: 0.5s;
  animation-name: slide-right;
  animation-duration: 0.5s;
}
.cntl-animate[_ngcontent-c15]:nth-child(2n + 1) .cntl-content[_ngcontent-c15] {
  -webkit-animation-name: slide-down;
  -webkit-animation-duration: 1s;
  animation-name: slide-down;
  animation-duration: 1s;
}
.cntl-animate[_ngcontent-c15]:nth-child(2n + 2) .cntl-content[_ngcontent-c15] {
  -webkit-animation-name: slide-text;
  -webkit-animation-duration: 1s;
  animation-name: slide-text;
  animation-duration: 1s;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translatex(-140%);
    transform: translatex(-140%);
  }
  100% {
    -webkit-transform: translatex(0);
    transform: translatex(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translatex(-140%);
    transform: translatex(-140%);
  }
  100% {
    -webkit-transform: translatex(0);
    transform: translatex(0);
  }
}
@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translatey(-30%);
    transform: translatey(-30%);
  }
  100% {
    -webkit-transform: translatey(0);
    transform: translatey(0);
  }
}
@keyframes slide-down {
  0% {
    -webkit-transform: translatey(-30%);
    transform: translatey(-30%);
  }
  100% {
    -webkit-transform: translatey(0);
    transform: translatey(0);
  }
}
@-webkit-keyframes slide-text {
  0% {
    -webkit-transform: translate(-20%);
    transform: translate(-20%);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes slide-text {
  0% {
    -webkit-transform: translate(-20%);
    transform: translate(-20%);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
.cntl-state[_ngcontent-c15]:nth-child(2n + 2).cntl-animate
  .cntl-content[_ngcontent-c15] {
  margin-right: 0;
  padding-right: 280px;
  padding-left: 0;
}
.cntl-animate[_ngcontent-c15] .cntl-content[_ngcontent-c15] {
  opacity: 1;
  margin-left: 0;
  background: 0 0;
  margin-top: 80px;
}
.cntl-animate[_ngcontent-c15] .cntl-content[_ngcontent-c15]:before {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 3px dotted #8a7974;
  position: absolute;
  top: 55px;
  left: 0;
}
.cntl-animate[_ngcontent-c15] .cntl-image[_ngcontent-c15] {
  opacity: 1;
}
@media (max-width: 991px) {
  .cntl-content[_ngcontent-c15] h1[_ngcontent-c15] {
    font-size: 26px !important;
  }
  .cntl-animate[_ngcontent-c15] .cntl-content[_ngcontent-c15]:before {
    top: 38px;
  }
  .cntl-bar[_ngcontent-c15] {
    left: auto;
    right: 37px;
  }
  .cntl-content[_ngcontent-c15] {
    width: 80%;
    float: none;
    padding: 0 2em !important;
    margin-top: 0 !important;
  }
  .cntl-content[_ngcontent-c15] p[_ngcontent-c15],
  .cntl-state[_ngcontent-c15] {
    margin-bottom: 30px;
  }
  .cntl-state[_ngcontent-c15]:nth-child(2n + 2) .cntl-content[_ngcontent-c15] {
    margin-right: 0;
    float: none;
  }
  .cntl-image[_ngcontent-c15] {
    width: 100%;
  }
  .cntl-state[_ngcontent-c15]:nth-child(2n + 1) .cntl-image[_ngcontent-c15] {
    float: none;
  }
  .animate[_ngcontent-c15] .cntl-content[_ngcontent-c15] {
    margin-left: 2%;
  }
  .cntl-icon[_ngcontent-c15] {
    left: auto;
    right: 0;
    top: 0;
    width: 100px;
    height: 100px;
  }
  .cntl-icon[_ngcontent-c15] h1[_ngcontent-c15] {
    font-size: 22px;
  }
  .cntl-icon[_ngcontent-c15] h4[_ngcontent-c15] {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .header-banner[_ngcontent-c15] {
    margin-bottom: 40px;
  }
  .header-banner[_ngcontent-c15] h2[_ngcontent-c15] {
    font-size: 30px;
    margin-bottom: 10px !important;
  }
  .header-banner[_ngcontent-c15] h4[_ngcontent-c15] {
    font-size: clamp(14px, 1.4vw, 20px);
    font-weight: 600;
  }
  .brand-title-content[_ngcontent-c15] h1[_ngcontent-c15] {
    font-size: 40px;
    line-height: 45px !important;
  }
}
